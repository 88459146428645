import "./Documenten.scss";
import {useEffect, useState} from "react";
import calculatedPercentage from "../../helpers/calculatedPercentage.js";
import {filterDocumentsByTitle, filterDocumentsByType, filterDocumentsByUser} from "../../helpers/filterDocuments.js";
import {ReactSVG} from "react-svg";
import Document from "./Document/Document";
import { extensionChecker } from "../../helpers/extensionChecker";
import { extensionToImage } from "../../helpers/extensionToImage";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import UploadFilePopup from "./UploadFilePopup/UploadFilePopup";
import avatar from "../../assets/avatar.jpg";

const Documenten = () => {
    const {token, selectedProjectID} = useStateContext();
    const [amountToBeRendered, setAmountToBeRendered] = useState(5);
    const [filteredDocuments, setFilteredDocuments] = useState();
    const [userFilterOpen, setUserFilterOpen] = useState(false);
    const [userToBeFiltered, setUserToBeFiltered] = useState("");
    const [typeToBeFiltered, setTypeToBeFiltered] = useState("");
    const [documentsFromApi, setDocumentsFromApi] = useState();
    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        axiosClient({
            method: 'get',
            url: `/api/v1/Documents/`,
            params: {
                project_id: selectedProjectID,
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            let _documentsFromApi = [];
            for(let item in response.data.documents){
                _documentsFromApi.push(response.data.documents[item]);
            }
            _documentsFromApi.reverse();
            setDocumentsFromApi(_documentsFromApi);
          });
    },[selectedProjectID, token]);

    useEffect(() => {
        if(!documentsFromApi) return;
        setFilteredDocuments(documentsFromApi);
    },[documentsFromApi]);

    useEffect(()=>{ //search documents
        if(!documentsFromApi) return;
        let filterDocumentsVariable = [];
        filterDocumentsVariable = filterDocumentsByTitle(documentsFromApi, searchInput);
        if (typeToBeFiltered !== ""){ filterDocumentsVariable = filterDocumentsByType(filterDocumentsVariable, typeToBeFiltered) }
        if (userToBeFiltered !== ""){ filterDocumentsVariable = filterDocumentsByUser(filterDocumentsVariable, userToBeFiltered) }
        setFilteredDocuments(filterDocumentsVariable);
    },[userToBeFiltered, typeToBeFiltered, searchInput, documentsFromApi]);


    useEffect(()=>{
        if(!filteredDocuments) return;
        if(amountToBeRendered > filteredDocuments.length){
            setAmountToBeRendered(filteredDocuments.length);
        }
    },[amountToBeRendered, filteredDocuments])

    useEffect(()=>{
        if(!filteredDocuments) return;
        if (filteredDocuments.length > 5){ setAmountToBeRendered(5)
        }else{ setAmountToBeRendered(filteredDocuments.length) }
    },[filteredDocuments])



    const loadMoreDocuments = () =>{
        setAmountToBeRendered(amountToBeRendered + 5);
    }

    const onTypeFilterClicked = (type) =>{
        if(typeToBeFiltered === type){
            setTypeToBeFiltered("")
        }else{
            setTypeToBeFiltered(type);
        }
    }

    const namesArray = [];
    const typesArray = [];
    if(documentsFromApi){
        if(!documentsFromApi) return;
        documentsFromApi.forEach(document =>{

            let m = namesArray.some(function(item) {
                return item.name === document.company_name
            });

            if(!m){
                namesArray.push({
                    name: document.company_name,
                    link:  document.company_image_url
                });
            }
            if(!typesArray.includes(extensionChecker(document.extension))){ 
                typesArray.push(extensionChecker(document.extension));
            }
        });
    }

    if(!filteredDocuments) return <p>Loading...</p>;

    return(<>
        <section className="section documenten">
            <header className="documenten__header">
                <h2 className="documenten__header__title main-title">Documenten</h2>
                <div className="documenten__header__form">
                    <input onChange={(ev)=>setSearchInput(ev.target.value)} placeholder="Zoek naar documenten, berichten of updates" className="documenten__header__form__input" type="text"/>
                    <button className="documenten__header__form__button">
                        <i className="fa-solid fa-magnifying-glass documenten__header__form__button--icon"></i>
                    </button>
                </div>
                <div className="documenten__header__wrapper">
                    <div className="documenten__header__filterwrapper">
                        {typesArray.map((type, index)=>{
                            if (index < 3){return (
                                <button key={index} onClick={() => onTypeFilterClicked(type.toLowerCase())} className={`btn documenten__header__filterwrapper__doctypefilter ${(type.toLowerCase() === typeToBeFiltered.toLowerCase()) && "documenten__header__filterwrapper__doctypefilter--selected"}`}>
                                    <ReactSVG src={extensionToImage(type.toLowerCase())} className="documenten__header__filterwrapper__doctypefilter--icon"></ReactSVG>
                                    <p className="documenten__header__filterwrapper__doctypefilter--text">{type} bestanden</p>
                                </button>
                            )} 
                            return null;
                        })}
                        {!userFilterOpen &&
                            <button onClick={() => setUserFilterOpen(!userFilterOpen)} className="documenten__header__filterwrapper__uploadedbyfilter">
                                {userToBeFiltered ? namesArray.map((item, index) =>{
                                    if(item.name === userToBeFiltered){return(
                                        <div key={index} className="documenten__header__filterwrapper__uploadedbyfilter__figurewrapper">
                                            <figure className="documenten__header__filterwrapper__uploadedbyfilter__figure">
                                                <img className="documenten__header__filterwrapper__uploadedbyfilter__figure--img" src={item.link} alt=""/>
                                            </figure>
                                        </div>
                                    )}
                                    return null;
                                }) : <div className="documenten__header__filterwrapper__uploadedbyfilter__figurewrapper">
                                        <figure className="documenten__header__filterwrapper__uploadedbyfilter__figure">
                                            <img className="documenten__header__filterwrapper__uploadedbyfilter__figure--img" src={avatar} alt=""/>
                                        </figure>
                                    </div>
                                }
                                <i className="fa-solid fa-chevron-down documenten__header__filterwrapper__uploadedbyfilter--chevron"></i>
                            </button>
                        }
                        {userFilterOpen &&
                            <div className="documenten__header__filterwrapper__userfilterpopup">
                                <ul className="userfilterpopup__ul">
                                    <li onClick={()=>{setUserToBeFiltered(null);setUserFilterOpen(false)}} className="userfilterpopup__ul__li">
                                        <figure className="userfilterpopup__ul__li__figure">
                                            <img className="userfilterpopup__ul__li__figure--img" src={avatar} alt=""/>
                                        </figure>
                                        <h3 className="userfilterpopup__ul__li__name">---</h3>
                                    </li>
                                    {namesArray.map((item, index) =>(
                                        <li key={index} onClick={()=>{setUserToBeFiltered(item.name);setUserFilterOpen(false)}} className="userfilterpopup__ul__li">
                                            <figure className="userfilterpopup__ul__li__figure">
                                                <img className="userfilterpopup__ul__li__figure--img" src={item.link} alt=""/>
                                            </figure>
                                            <h3 className="userfilterpopup__ul__li__name">{item.name}</h3>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                    </div>
                    <UploadFilePopup setDocumentsFromApi={setDocumentsFromApi}/>
                </div>
            </header>
            <div className="documenten__titlewrapper">
                <h2 className="documenten__titlewrapper__title">Overzicht</h2>
            </div>
            
            <div className="documenten__documentwrapper">
            {filteredDocuments.map((document, index) =>{
                if(!(index > (amountToBeRendered - 1))){
                    return <Document document={document} key={index}/>;
                }
                return null;
            })}
            </div>
            <footer className="documenten__footer">
                <p className="documenten__footer__amountshown">Toont {amountToBeRendered} van de {filteredDocuments.length} documenten</p>
                <div className="documenten__footer__amountprogressbar">
                    <div className="documenten__footer__amountprogressbar--progress" style={{width: `${calculatedPercentage(amountToBeRendered, filteredDocuments.length)}%`}}></div>
                </div>
                {amountToBeRendered < filteredDocuments.length &&
                    <button onClick={loadMoreDocuments} className="documenten__footer__loadmorebutton btn">Meer documenten inladen</button>
                }
            </footer>

        </section></>
    );
}

export default Documenten;
