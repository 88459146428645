import { useEffect, useState } from "react";
import AkkoordNodig from "../Components/AkkoorNodig/AkkoordNodig";
import GoedgekeurdDoor from "../Components/GoedgekeurdDoor/GoedgekeurdDoor";
import UrenBalk from "../Components/UrenBalk/UrenBalk";
import "./ProjectDetail.scss";

const ProjectDetail =({phase}) =>{
    const [projectDetailOpen, setProjectDetailOpen] = useState(false);
    const [phaseData, setPhaseData] = useState();
    useEffect(() => {
        if(phase.lines.length > 0){
            setProjectDetailOpen(true)
        }
        setPhaseData(phase);
    }, [phase])

    const akkoordNodigArray = ["vooronderzoek", "design", "meerwerk"];

    if(!phaseData)return <div>Loading...</div>;
    
    return(

        <div id={phaseData.name.toLowerCase().replace(/\s/g, '')} className="projectDetails__detail">
            <div className={`projectDetailsTitle ${!projectDetailOpen && "projectDetailsTitle--isclosed"}`}>
                <h3 className="projectDetailsTitle__title">{phaseData.name}</h3>
                {projectDetailOpen && <i onClick={()=>setProjectDetailOpen(!projectDetailOpen)} className="fa-solid fa-minus"></i>}
                {!projectDetailOpen && <i onClick={()=>setProjectDetailOpen(!projectDetailOpen)} className="fa-solid fa-plus"></i>}
            </div>
            <div className={`projectDetails__detail__akkoordwrapper projectDetails__detail__info ${!projectDetailOpen && "projectDetails__detail__info--isclosed"}`}>
                {phaseData.lines.map((line, index) => {
                    return(
                        <div key={index} className="projectDetails__detail__info__wrapper">
                            <UrenBalk text={line.title} hours={line.time_available} completedHours={line.time_used}/>
                            {akkoordNodigArray.includes(phaseData.name.toLowerCase()) && <>
                            {line.approved_name && line.signature && <GoedgekeurdDoor name={line.approved_name} date={line.approved_date} />}
                            {!line.signature && (parseInt(line.status_id) === 1) && <AkkoordNodig phaseData={phaseData} setPhaseData={setPhaseData} phaseId={phaseData.phase_id} projectLineId={line.id} />}
                            </>}
                        </div>
                    );
                })}
            </div>
        </div>
    )
}


export default ProjectDetail;