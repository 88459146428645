// import { ReactSVG } from "react-svg";
import "./ProjectenOverzicht.scss";
// import wrenchImg from "../../assets/wrench-tool-icon.svg";
// import settingImg from "../../assets/setting-icon.svg";
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import ProjectOverzichtCard from "./ProjectOverzichtCard";

const ProjectenOverzicht = () => {
    const [projects, setProjects] = useState();
    const {token} = useStateContext();

    useEffect(()=>{
        axiosClient({
            method: 'get',
            url: '/api/v1/Projects',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjects(response.data);
          });
    }, [token]);


    return(
        <section className="projectoverzicht">
            <header className="projectoverzicht__header">
                <h1 className="main-title projectoverzicht__header__title">Projecten</h1> 
                <div className="projectoverzicht__header__filterwrapper">
                    {/* <button className="btn projectoverzicht__header__filterwrapper__filter">  // momenteel nog niet nodig maar mogelijk binnenkort wel
                        <div className="projectoverzicht__header__filterwrapper__filter__icon">
                            <ReactSVG src={wrenchImg} />
                        </div>
                        <p className="projectoverzicht__header__filterwrapper__filter__text">Bezig</p>
                    </button>
                    <button className="btn projectoverzicht__header__filterwrapper__filter">
                        <div className="projectoverzicht__header__filterwrapper__filter__icon">
                            <ReactSVG src={settingImg} />
                        </div>
                        <p className="projectoverzicht__header__filterwrapper__filter__text">Afgerond</p>
                    </button> */}
                </div>
            </header>
            <div className="projectoverzicht__projecten">
                {projects && projects.projects.map((project, index)=>(
                    <ProjectOverzichtCard key={index} project={project} />
                ))}
            </div>
        </section>
    );
}

export default ProjectenOverzicht;