import {createBrowserRouter, Navigate} from "react-router-dom";
import Login from "./views/Login.jsx";
import NotFound from "./views/NotFound.jsx";
import DefaultLayout from "./components/DefaultLayout.jsx";
import GuestLayout from "./components/GuestLayout.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import AdminLayout from "./components/AdminLayout.jsx"
import Support from "./components/Support/Support.jsx";
import Documenten from "./components/Documenten/Documenten.jsx";
import Projecten from "./components/Projecten/Projecten.jsx";
import OfferteOndertekenen from "./components/OfferteOndertekenen/OfferteOndertekenen.jsx";
import Items from "./components/Items/Items.jsx";
import Berichten from "./components/Berichten/Berichten.jsx";
import Overview from "./views/Overview.jsx";
import ProjectenOverzicht from "./components/ProjectenOverzicht/ProjectenOverzicht.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
import AdminUpdates from "./components/AdminUpdates/AdminUpdates.jsx";
import AdminItems from "./components/AdminItems/AdminItems.jsx";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";

const router = createBrowserRouter([
    {
        path: '/',
        element: <PrivateRoute><DefaultLayout /></PrivateRoute>,
        children: [
            {
                path: '/',
                element: <Navigate to="/projecten" />
            },
            {
                path: '/projecten',
                element: <Projecten />
            },
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '/berichten',
                element: <Berichten />
            },
            {
                path: '/items',
                element: <Items />
            },
            {
                path: '/support',
                element: <Support />
            },
            {
                path: '/documenten',
                element: <Documenten />
            },
            {
                path: '/offerte',
                element: <OfferteOndertekenen />
            },
        ]
    },
    {
        path: '/',
        element: <PrivateRoute><AdminLayout /></PrivateRoute>,
        children: [
            {
                path: '/projectadmin',
                element: <Projecten />
            },
            {
                path: '/adminupdates',
                element: <AdminUpdates />
            },
            {
                path: '/adminitems',
                element: <AdminItems />
            },
            {
                path: '/admindocuments',
                element: <Documenten />
            },
        ]
    },
    {
        path: '/',
        element: <GuestLayout />,
        children: [
            {
                path: '/wachtwoord-vergeten',
                element: <ForgotPassword />
            },
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/overview',
                element: <PrivateRoute><Overview /></PrivateRoute>
            },
            {
                path: '/adminoverview',
                element: <PrivateRoute><ProjectenOverzicht /></PrivateRoute>
            },
        ]
    },

    {
        path: '/',
        element: <NotFound />
    }
])

export default router;
