import { useState } from "react";
import { dateDifferenceChecker } from "../../../helpers/dateDifferenceChecker";
import SelectedBericht from "../SelectedBericht/SelectedBericht";
import "./Bericht.scss";
import databaseIntToBool from "../../../helpers/databaseIntToBool";
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../contexts/ContextProvider";

const Bericht = ( {bericht, setBaseBerichten, baseBerichten} ) => {
    const [selectedMessageOpen, setSelectedMessageOpen] = useState(false);
    const {token, user} = useStateContext();
    const params = {apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',token: token,submit: 'submit'}

    const changeArchived = () =>{
        axiosClient({
            method: 'patch',
            url: `/api/v1/Updates`,
            data: {
                archived: databaseIntToBool(parseInt(bericht.archived)) ? "0" : "1",
                update_id: bericht.id,
                project_id: bericht.project_id
            },
            params: params
        })
        setBaseBerichten(baseBerichten.map(_bericht => _bericht.id === bericht.id ? {..._bericht, archived: databaseIntToBool(parseInt(bericht.archived)) ? "0" : "1"} : _bericht));
        setSelectedMessageOpen(false);
    }
    const changeRead = () =>{
        if(user.is_admin)return;
        axiosClient({
            method: 'patch',
            url: `/api/v1/Updates`,
            data: {
                read: databaseIntToBool(parseInt(bericht.read)) ? "0" : "1",
                update_id: bericht.id,
                project_id: bericht.project_id
            },
            params: params
        })
        setBaseBerichten(baseBerichten.map(_bericht => _bericht.id === bericht.id ? {..._bericht, read: databaseIntToBool(parseInt(bericht.read)) ? "0" : "1"} : _bericht));
    }

    return(
        <>
        {selectedMessageOpen &&  <SelectedBericht changeArchived={changeArchived} changeRead={changeRead} setSelectedMessageOpen={setSelectedMessageOpen} bericht={bericht}/>}
        <div className="berichten__bericht">
            <div onClick={()=>setSelectedMessageOpen(!selectedMessageOpen)} className="berichten__bericht__textwrapper">
                <figure className="berichten__bericht__textwrapper__figure">
                    <p className="berichten__bericht__textwrapper__figure__uploadedby" >Van {bericht.created_name || "unknown"}</p>
                    <img className="berichten__bericht__textwrapper__figure--img" src={bericht.company_image_url} alt="profiel foto"/>
                </figure>
                <div className="berichten__bericht__textwrapper__div">
                    <p className="berichten__bericht__textwrapper__div--title">{bericht.title}</p>
                    <p className="berichten__bericht__textwrapper__div--date">{dateDifferenceChecker(bericht.created_date)}</p>
                    {databaseIntToBool(parseInt(bericht.important)) && <i className="fa-solid fa-triangle-exclamation berichten__bericht__textwrapper__div--important"></i>}
                </div>
            </div>
            <div className="berichten__bericht__btnwrapper">
                <button onClick={changeRead} className="berichten__bericht__btnwrapper__btn">
                    {!databaseIntToBool(parseInt(bericht.read)) && <i className="fa-regular fa-eye-slash"></i>}
                    {databaseIntToBool(parseInt(bericht.read)) && <i className="fa-regular fa-eye"></i>}
                </button>
                <button onClick={changeArchived} className="berichten__bericht__btnwrapper__btn">
                    {!databaseIntToBool(parseInt(bericht.archived)) && <i className="fa-regular fa-folder"></i>}
                    {databaseIntToBool(parseInt(bericht.archived)) && <i className="fa-solid fa-folder"></i>}
                </button>
                <button onClick={()=>setSelectedMessageOpen(!selectedMessageOpen)} className="berichten__bericht__btnwrapper__btn">
                    <i className="fa-solid fa-info"></i>
                </button>
            </div>
        </div>
        </>
    );
}

export default Bericht;