import "./Support.scss";

const Support = () => {
    return(
        <section>
            <h1>Support</h1>
        </section>
    );
}

export default Support;