import {Outlet} from "react-router-dom";
import "./GuestLayout.scss";
import LeftLoginPane from "./LeftLoginPane/LeftLoginPane";

export default function QuestLayout() {
    return(
        <section className="guestlayout">
            <LeftLoginPane/>
            <div className="login__rightpane">
                <Outlet/>
            </div>
        </section>
    );
}
