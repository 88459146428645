import "./ForgotPassword.scss";
import "../../views/Login.scss";
import axiosClient from "../../axios-client";
import {Link, useSearchParams } from "react-router-dom";
import { createBrowserHistory } from "history";
import {useState, useRef} from "react";


const ForgotPassword = () => {

    let history = createBrowserHistory();
    const emailRef = useRef();
    const pwdRef = useRef();
    const pwdRptRef = useRef();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [queryParameters] = useSearchParams()

    const hash = queryParameters.get('hash');

    if(hash === null){ 
        history.push('/login');
        window.location.reload();
    }

    const onSubmit = (ev) =>{
        ev.preventDefault()
        setError(null);
        setSuccess(null);
            axiosClient({
                method: 'post',
                url: `/api/v1/ChangeForgetPassword`,
                data: {
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    hash: hash,
                    email: emailRef.current.value,
                    password: pwdRef.current.value,
                    repeat_password: pwdRptRef.current.value,
                },
            }).then(function (response) {
                if(response.data.status === false)
                {
                    setError(response.data.error);
                } else {
                    setSuccess(response.data.success);
                }
            })
    }

    return (

        <div className="loginform">
            <h2 className="loginform__h2">Wachtwoord resetten</h2>
            {error && <div className="ForgetPasswordForm__errors">
                <p className="loginform__error">{error}</p>
            </div>}
            {!success && <form className="loginform__form" onSubmit={onSubmit}>
                <div className="loginform__form__inputWrapper">
                    <label className="loginform__form__inputWrapper__label" htmlFor="username">Email</label>
                    <input ref={emailRef} className="loginform__form__inputWrapper__input" type="email" name="email" id="email" placeholder="Uw email"/>
                </div>
                <div className="loginform__form__inputWrapper">
                    <label className="loginform__form__inputWrapper__label" htmlFor="password">Wachtwoord</label>
                    <input ref={pwdRef} className="loginform__form__inputWrapper__input" type="password" name="password" id="password" placeholder="Uw wachtwoord"/>
                </div>
                <div className="loginform__form__inputWrapper">
                    <label className="loginform__form__inputWrapper__label" htmlFor="password">Wachtwoord herhalen</label>
                    <input ref={pwdRptRef} className="loginform__form__inputWrapper__input" type="password" name="repeat_password" id="repeat_password" placeholder="Uw wachtwoord"/>
                </div>
                <button className="loginform__form__loginButton btn" onClick={onSubmit}>Wachtwoord wijzigen</button>
            </form>}
            {success && <div className="loginform__form">
                <div className="loginform__form__pwresetsuccess">
                    Wachtwoord is gewijzigd
                </div>
                <Link to="/login" className="loginform__form__loginButton btn">Naar login</Link>
            </div>}
        </div>

    )
}

export default ForgotPassword;