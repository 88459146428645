import "./SignaturePopup.scss";
import SignaturePad from 'react-signature-canvas'
import axiosClient from "../../axios-client";
import {useStateContext} from "../../contexts/ContextProvider";
import {useEffect, useRef, useState} from "react";

const  SignaturePopup = ({setOfferteOndertekend, setPhaseData, phaseData, setSignaturePopupOpen, setPhaseId, setName, projectLineId}) =>{
    const {token, selectedProjectID} = useStateContext();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [approveName, setApproveName] = useState();
    const approveNameRef = useRef();

    useEffect(() => {
        if(setName){
            setApproveName(setName);
        }
    }, [setName]);

    useEffect(() => {
        if(success){
            if(phaseData){
                setTimeout(() => {
                    const _phaseDataLines = phaseData.lines.map((line) => {
                        if(line.id === projectLineId){
                            line.signature = "signature";
                            line.approved_name = approveName;
                            line.approved_date = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
                            return line;
                        } else {
                            return line;
                        }
                    });
                    setPhaseData({...phaseData, lines: _phaseDataLines});
                    setSignaturePopupOpen(false);
                }, 2000)
            }
            if(setOfferteOndertekend){
                setTimeout(() => {
                    setOfferteOndertekend(true);
                    setSignaturePopupOpen(false);
                }, 2000)
            }
        }
    }, [success]);

    let sigPad = {}
    let clear = () => {
        sigPad.clear()
    }

    const onSignatureSubmit = (e) =>{
        e.preventDefault();
        setError(null);
        setSuccess(null);
        if(sigPad.toDataURL() !== '' && setPhaseId && approveName){
            axiosClient({
                method: 'PUT',
                url: `/api/v1/ProjectLines/`,
                data: {
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    token: token,
                    id: projectLineId,
                    project_id: selectedProjectID,
                    phase_id: setPhaseId,
                    signature: sigPad.toDataURL(),
                    approve_name: approveName,
                }
            }).then(function (response) {
                if(response.data.success === false)
                {
                    setError(response.data.error);
                } else {
                    setSuccess('Akkoord is succesvol bevestigd');
                }
            })
        } else if(sigPad.toDataURL() !== '' && selectedProjectID  && setName){
            axiosClient({
                method: 'PATCH',
                url: `/api/v1/Projects/`,
                data: {
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    token: token,
                    project_id: selectedProjectID,
                    signature: sigPad.toDataURL(),
                    approve_name: setName,
                }
            }).then(function (response) {
                if(response.data.success === false)
                {
                    setError(response.data.error);
                } else {
                    setSuccess('Akkoord is succesvol bevestigd');
                }
            })
        }
    }

    const onNameSubmit = () =>{
        setApproveName(approveNameRef.current.value);
    }

    return(
        <div className="SignaturePopup is-open">
            <div className="clickablebackground" ></div>
            {success && <div className="forgotPWPopup__success">
                <p className="forgotPWPopup__success__p">{success}</p>
            </div> }
            {!success && <form className="SignaturePopup__form" onSubmit={(e) => {e.preventDefault()}}>
                <div className="SignaturePopup__form__header">
                    {
                        setPhaseId ? (
                            <h1 className="SignaturePopup__form__header--h1">Onderteken uw project detail</h1>
                        ) : (
                            <h1 className="SignaturePopup__form__header--h1">Onderteken uw offerte</h1>
                        )
                    }

                    <i onClick={()=>setSignaturePopupOpen(false)} className="fa-solid fa-xmark signaturePopup__form__header__back"></i>
                </div>
                
                
                <input type="hidden" id="js--user-signature"/>
                <div className="SignaturePopup__form__inputWrapper">
                    <label className="SignaturePopup__form__inputWrapper--label" htmlFor="signature">Schrijf hieronder uw handtekening</label>
                    {approveName && <>{error && <div className="forgotPWPopup__errors">
                        <p className="forgotPWPopup__error__p">{error}</p>
                    </div>}
                    <div className="signature-pad-wrapper">
                        <SignaturePad id="signature-pad"
                                        canvasProps={{ width: 500, height: 200, className: "signature-pad" }}
                                        ref={(ref) => { sigPad = ref }}
                        />
                        <div id="signature-pad-clear" onClick={clear}>Opnieuw</div>
                    </div></>}
                    {!approveName && <input ref={approveNameRef} className="SignaturePopup__form__inputWrapper__name" type="text" placeholder="Uw voor en achternaam"/>}

                </div>
                <div className="SignaturePopup__form__buttonWrapper">
                    {approveName && <>
                    {setPhaseId && <button onClick={onSignatureSubmit} className="SignaturePopup__form__buttonWrapper--submit">Bevestig handtekening</button>}
                    {!setPhaseId && <button onClick={onSignatureSubmit} className="SignaturePopup__form__buttonWrapper--submit">Kostenindicatie goedkeuren</button>}
                    {!setPhaseId && <div className="SignaturePopup__form__buttonWrapper--bottomText">
                        <p>Bij handtekening geplaatst gaat u akkoord met de <a href="#kostenindicatie">kostenindicatie.</a></p>
                    </div>}</>}
                    {!approveName &&  <button onClick={onNameSubmit} className="SignaturePopup__form__buttonWrapper--submit">Bevestig naam</button>}
                </div>
            </form>}
        </div>
    );
}

export default SignaturePopup;