import "./Login.scss";
import Vinkje from "../assets/Vinkje.svg";
import {ReactSVG} from "react-svg";
import {useState, useEffect, useRef} from "react";
import ForgotPasswordPopup from "../components/ForgotPasswordPopup/ForgotPasswordPopup.jsx";
import { useStateContext } from "../contexts/ContextProvider";
import axiosClient from "../axios-client";
import { Navigate } from "react-router-dom";
export default function Login() {
    const {setUser, setToken} = useStateContext()
    const emailRef = useRef();
    const pwdRef = useRef();
    const [popupOpen, setPopupOpen] = useState();
    const [error, setError] = useState(null);
    const {token, user} = useStateContext();

    useEffect(()=>{
        setPopupOpen(false);
    },[])

    if (token) {
        if(user.is_admin){
            return  <Navigate to="/adminoverview" />
        }
        return  <Navigate to="/overview" />
    }

    const changePopupOpen = () =>{
        setPopupOpen(!popupOpen);
    }

    const onSubmit = (ev) =>{
        ev.preventDefault()

        setError(null);
        axiosClient({
            method: 'post',
            url: `/api/v1/Login`,
            data: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                email: emailRef.current.value,
                password: pwdRef.current.value,
                submit: 'submit'
            }
        }).then(({data})=>{
                setUser(data.user);
                setToken(data.token);
                if(data.error){
                    setError(data.error);
                }
            })
    }

    return (
        <div className="loginform">
            <ForgotPasswordPopup popupOpen={popupOpen} changePopupOpen={changePopupOpen}/>
            <h2 className="loginform__h2">Inloggen</h2>
            {error && <div className="loginform__errors">
                <p className="loginform__error">{error}</p>
            </div>}
            <div className="loginform__form">
                <div className="loginform__form__inputWrapper">
                    <label className="loginform__form__inputWrapper__label" htmlFor="username">Gebruikersnaam</label>
                    <input ref={emailRef} className="loginform__form__inputWrapper__input" type="text" name="username" id="username" placeholder="Uw gebruikersnaam"/>
                </div>
                <div className="loginform__form__inputWrapper">
                    <label className="loginform__form__inputWrapper__label" htmlFor="password">Wachtwoord</label>
                    <input ref={pwdRef} className="loginform__form__inputWrapper__input" type="password" name="password" id="password" placeholder="Uw wachtwoord"/>
                </div>
                <div className="loginform__form__loginOptionsWrapper">
                        <input className="loginOptionsWrapper__inputWrapper__input loginOptionsWrapper__inputWrapper__input--staysignedin" type="checkbox" name="staysignedin" id="staysignedin"/>
                        <label className="loginOptionsWrapper__inputWrapper__label loginOptionsWrapper__inputWrapper__label--staysignedin" htmlFor="staysignedin">
                            Ingelogd blijven
                        
                            <span className="loginOptionsWrapper__inputWrapper__span">
                                <ReactSVG src={Vinkje} alt="vinkje"  className="loginOptionsWrapper__inputWrapper__span--icon"/>
                            </span>
                            
                        </label>
                    
                    <button className="loginOptionsWrapper__forgotpwd" onClick={changePopupOpen}>Wachtwoord vergeten?</button>
                </div>
                <button className="loginform__form__loginButton btn" onClick={onSubmit}>Inloggen</button>
            </div>
        </div>
    )
}
