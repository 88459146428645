import { useRef, useState } from "react";
import "./UploadFilePopup.scss";
import uploadfilesicon from "../../../assets/uploadfilesicon.svg";
import { ReactSVG } from "react-svg";
import { useStateContext } from "../../../contexts/ContextProvider";
import axiosClient from "../../../axios-client";
import { isEmptyObject } from 'jquery';


const UploadFilePopup = ({setDocumentsFromApi}) => {
    const [uploadFilePopup, setUploadFilePopup] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const {token, selectedProjectID, user} = useStateContext();

    const uploadFilePopupRef = useRef();
    const fileRef = useRef();

    window.addEventListener('click', ({target})=> {
        if(!uploadFilePopupRef.current)return;
        if(!uploadFilePopupRef.current.innerHTML.includes(target.innerHTML)){
            setUploadFilePopup(false);
        }
    });

    const onUploadFile = (ev) =>{
        ev.preventDefault();
        let file = fileRef.current.files[0];

        const formData = new FormData();
        formData.append('document', file);
        
        if(!isEmptyObject(file))
        {
            console.log('file selected');
            axiosClient({
                method: 'post',
                url: `/api/v1/Documents/`,
                data: {
                    document: file,
                    token: token,
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    project_id: 4760,
                    submitType: 'post',
                    submit: 'submit',
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                  },
            }).then(function (response) {
                axiosClient({
                    method: 'get',
                    url: `/api/v1/Documents/`,
                    params: {
                        project_id: selectedProjectID,
                        apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                        token: token,
                        submit: 'submit'
                    }
                }).then(function (response) {
                    let _documentsFromApi = [];
                    for(let item in response.data.documents){
                        _documentsFromApi.push(response.data.documents[item]);
                    }
                    _documentsFromApi.reverse();
                    setDocumentsFromApi(_documentsFromApi);
                }); 
                if(response.data.success){
                    setSuccess(true);
                    axiosClient({
                        method: 'post',
                        url: '/api/v1/SendMail',
                        data: {
                            token: token,
                            apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                            emailName: "Document toegevoegd",
                            projectId: selectedProjectID
                        },
                    })
                    axiosClient({
                        method: 'post',
                        url: `/api/v1/Updates/`,
                        data: {
                            token: token,
                            apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                            title: `${user.name} heeft een bestand geupload`,
                            message: `Er is een nieuw bestand geupload door ${user.name}.`,
                            project_id: selectedProjectID,
                        }
                    })
                };
                if(response.data.error)setError(response.data.error);

              })
        } else {
            console.log('no file selected');
        }

   
    }

    return(
        <div ref={uploadFilePopupRef}>
        <button onClick={()=>setUploadFilePopup(!uploadFilePopup)} className="btn btn--greenwhite">
            <ReactSVG src={uploadfilesicon} />
            Bestand uploaden
        </button>
        {uploadFilePopup && <div className="uploadfilepopup">
            <h2 className="uploadfilepopup__title">Bestand uploaden</h2>
            <form className="uploadfilepopup__form" onSubmit={onUploadFile}>
                {success && <p className="uploadfilepopup__form__inputwrapper__success">✔ Bestand succesvol geupload</p>}
                {!success && <input ref={fileRef} className="uploadfilepopup__form__inputwrapper__input" type="file" name="file" id="file"/>}
                {error && <p className="uploadfilepopup__form__inputwrapper__error">{error}</p>}
                {!success && <button className="uploadfilepopup__form__button btn">Toevoegen</button>}
                {success && <div onClick={()=>setUploadFilePopup(false)} className="uploadfilepopup__form__button btn">Sluit</div>}
            </form>
        </div>}
        </div>
    );
}

export default UploadFilePopup;