import "./NotificationsPopup.scss";
import placeholderPFP from "../../assets/placeholderPFP.webp"
import {Link} from "react-router-dom";
import notifsData from "../../data/notifsData.js";
import { useRef } from "react";


const NotificationsPopup = ({setNotifOpen}) =>{
    const notifPopupRef = useRef();

    window.addEventListener('click', ({target})=> {
        if(!notifPopupRef.current)return;
        if(!notifPopupRef.current.innerHTML.includes(target.innerHTML)){
            setNotifOpen(false);
        }
    });

    return(
        <div ref={notifPopupRef} className="notificationsPopup">
            <h1 className="notificationsPopup__title">Meldingen</h1>
            <div className="notificationWrapper">
                {notifsData && notifsData.map(notification =>(
                    <div key={notification.id} className="notification">
                        <img className="notification__img" src={notification.pfp || placeholderPFP} alt="avatar" />
                        <div className="notification__textwrapper">
                            <h2  className="notification__textwrapper__title"><b className=".notification__textwrapper__title--bold">{notification.name}</b> {notification.task}</h2>
                            <p className="notification__textwrapper__time">{notification.time}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="notificationsPopup__buttonwrapper">
                <Link to="/berichten" className="notificationsPopup__buttonwrapper__button" onClick={()=>setNotifOpen(false)}>Bekijk alle meldingen</Link>
            </div>
        </div>
    );
}

export default NotificationsPopup;
