import { useRef, useState } from "react";
import { dateDifferenceChecker } from "../../helpers/dateDifferenceChecker";
import "../Items/Item/Item.scss";
import databaseIntToBool from "../../helpers/databaseIntToBool";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import SelectedAdminItem from "./SelectedAdminItem";

const AdminItem = ({item, baseItems, setBaseItems}) => {
    const [selectedItemOpen, setSelectedItemOpen] = useState(false);
    const [statusPopupOpen, setStatusPopupOpen] = useState(false);
    
    const {token, selectedProjectID, user} = useStateContext();
    const statusPopupRef = useRef();

    const params = {apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',token: token,submit: 'submit'}
    const status = ["Ongelezen","in behandeling","Afgerond"]

    // const issueUsersToBeRendered = issue.users.map((user, index) =>{
    //     return(
    //         <figure className="issues__issue__btnwrapper__userwrapper__figure">  
    //             <p className="issues__issue__btnwrapper__userwrapper__figure__by" >{user || "unknown"}</p>
    //             <img className="issues__issue__btnwrapper__userwrapper__figure--img" src={placeholderPFP} alt=""/>
    //         </figure>
    //     );
    // })

    window.addEventListener('click', ({target})=> {
        if(!statusPopupRef.current)return;
        if(!statusPopupRef.current.innerHTML.includes(target.innerHTML)){
            setStatusPopupOpen(false);
        }
    });

    const changeArchived = () =>{
        axiosClient({
            method: 'patch',
            url: `/api/v1/Items`,
            data: {
                archived: databaseIntToBool(parseInt(item.archived)) ? "0" : "1",
                item_id: item.id,
                project_id: selectedProjectID
            },
            params: params
        }).then(function (response) {
            axiosClient({
                method: 'post',
                url: `/api/v1/Updates/`,
                data: {
                    token: token,
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    title: `${user.name} heeft een item gearchiveerd`,
                    message: `Er is een item gearchiveerd door ${user.name}.`,
                    project_id: selectedProjectID,
                }
            })
        })
        setBaseItems(baseItems.map(_item => _item.id === item.id ? {..._item, archived: databaseIntToBool(parseInt(item.archived)) ? "0" : "1"} : _item));
    }

    const changeStatus = (status_id) =>{
        axiosClient({
            method: 'patch',
            url: `/api/v1/Items`,
            data: {
                status_id: status_id,
                item_id: item.id,
                project_id: selectedProjectID
            },
            params: params
        }).then(function (response) {
            axiosClient({
                method: 'post',
                url: `/api/v1/Updates/`,
                data: {
                    token: token,
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    title: `${user.name} heeft een item geupdate`,
                    message: `Er is een item op "${status[status_id - 1]}" gezet door ${user.name}.`,
                    project_id: selectedProjectID,
                }
            })
        })
        setBaseItems(baseItems.map(_item => _item.id === item.id ? {..._item, status_id: status_id} : _item));
    }


    return(
        <>
        {selectedItemOpen &&  <SelectedAdminItem setSelectedItemOpen={setSelectedItemOpen} changeStatus={changeStatus} changeArchived={changeArchived} item={item}/>}
        <div className={`issues__issue ${selectedItemOpen && "body-overflow-hidden"}`}>
            <div className="issues__issue__textwrapper">
                <div onClick={()=>setSelectedItemOpen(!selectedItemOpen)} className="issues__issue__textwrapper__div">
                    <p className="issues__issue__textwrapper__div--title">{item.title}</p>
                    <p className="issues__issue__textwrapper__div--date">{dateDifferenceChecker(baseItems.filter(_item => parseInt(_item.id) === parseInt(item.id))[0].created_date)}</p>
                </div>
            </div>
            <div className="issues__issue__btnwrapper">
                <div ref={statusPopupRef} onClick={()=>setStatusPopupOpen(!statusPopupOpen)} className={`issues__issue__btnwrapper__status issues__issue__btnwrapper__status--${status[item.status_id - 1].toLowerCase()} issues__issue__btnwrapper__status--pointer`}>
                    {status[item.status_id - 1]}
                    {statusPopupOpen && <div className="issues__issue__btnwrapper__status__popup">
                        <button onClick={()=>changeStatus(1)} className="issues__issue__btnwrapper__status__popup__btn issues__issue__btnwrapper__status__popup__btn--unread">Ongelezen</button>
                        <button onClick={()=>changeStatus(2)} className="issues__issue__btnwrapper__status__popup__btn issues__issue__btnwrapper__status__popup__btn--bussy">In behandeling</button>
                        <button onClick={()=>changeStatus(3)} className="issues__issue__btnwrapper__status__popup__btn issues__issue__btnwrapper__status__popup__btn--complete">Afgerond</button>
                    </div>}
                </div>
                <button onClick={changeArchived} className="issues__issue__btnwrapper__btn">
                    {!databaseIntToBool(parseInt(item.archived)) && <i className="fa-regular fa-folder"></i>}
                    {databaseIntToBool(parseInt(item.archived)) && <i className="fa-solid fa-folder"></i>}
                </button>
                <button onClick={()=>setSelectedItemOpen(!selectedItemOpen)} className="issues__issue__btnwrapper__btn">
                    <i className="fa-solid fa-info"></i>
                </button>
            </div>
        </div>
        </>
    );
}

export default AdminItem;