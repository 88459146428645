import {createContext, useState, useContext} from "react";

const StateContext = createContext({
    user: null,
    token: null,
    notification: null,
    setUser: () => {},
    setToken: () => {},
    setNotification: () => {},
})

export const ContextProvider = ({children}) =>{
    const [user, _setUser] = useState(JSON.parse(sessionStorage.getItem('USER')));
    const [notification, _setNotification] = useState('');
    const [selectedProjectID, _setSelectedProjectID] = useState(sessionStorage.getItem('PROJECT_ID'));
    const [token, _setToken] = useState(sessionStorage.getItem('ACCESS_TOKEN')); //sessionStorage.getItem('ACCESS_TOKEN')

    const setNotification = (message) =>{
        _setNotification(message);
        setTimeout(()=>{
            _setNotification('');
        }, 5000)
    }

    const setToken = (token) =>{
        _setToken(token)
        if(token) {
            sessionStorage.setItem('ACCESS_TOKEN', token);
        }else{
            sessionStorage.removeItem( 'ACCESS_TOKEN');
        }
    }
    const setUser = (user) =>{
        _setUser(user)
        if(user) {
            sessionStorage.setItem('USER', JSON.stringify(user));
        }else{
            sessionStorage.removeItem( 'USER');
        }
    }
    const setSelectedProjectID = (id) =>{
        _setSelectedProjectID(id)
        if(id) {
            sessionStorage.setItem('PROJECT_ID', id);
        }else{
            sessionStorage.removeItem( 'PROJECT_ID');
        }
    }

    return(
        <StateContext.Provider value={{
            user,
            token,
            setUser,
            setToken,
            notification,
            setNotification,
            selectedProjectID,
            setSelectedProjectID,
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)
