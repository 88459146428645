import wordIcon from '../assets/word-icon.svg';
import excelIcon from '../assets/excel-icon.svg';
import powerpointIcon from '../assets/powerpoint-icon.svg';
import pdfIcon from '../assets/pdf-icon.svg';
import fileIcon from '../assets/file-icon.svg';

export const extensionToImage = (fileExtension) => {
    switch (fileExtension) {
        case 'word':
            return wordIcon;
        case 'excel':
            return excelIcon;
        case 'powerpoint':
            return powerpointIcon;
        case 'pdf':
            return pdfIcon;
        default:
            return fileIcon;
    }

}
