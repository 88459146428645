import "./Projecten.scss";
import ProjectVerloop from "./ProjectVerloop/ProjectVerloop";
import ProjectCoordinatie from "./ProjectCoordinatie/ProjectCoordinatie";
import Meerwerk from "./ProjectDetails/Meerwerk/Meerwerk";
import {Link} from "react-router-dom";
import TotaalMeerwerk from "./TotaalMeerwerk/TotaalMeerwerk";
import ProjectDetail from "./ProjectDetails/ProjectDetail/ProjectDetail";
import { useEffect, useState } from 'react';
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";



const Projecten = () => {
    const {token, selectedProjectID, user} = useStateContext();
    const [projectsFromApi, setProjectsFromApi] = useState();
    const [projectFromApi, setProjectFromApi] = useState();
    const [selectedProject, setSelectedProject] = useState();

    useEffect(() => {
        if(!projectsFromApi || !selectedProjectID)return;
        projectsFromApi.projects.forEach(project => {
            if(project.pm_project_id === selectedProjectID){
                setSelectedProject(project);
            }
        })
        axiosClient({
            method: 'get',
            url: `/api/v1/Projects/${selectedProjectID}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjectFromApi(response.data);
        });
    },[projectsFromApi, selectedProjectID, token]);

    useEffect(() => {
        axiosClient({
            method: 'get',
            url: '/api/v1/Projects',
            withCredentials: false,
            crossdomain: true,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjectsFromApi(response.data);
        });
    },[token]);

    if(!projectFromApi || !selectedProject){
        return <div>Loading...</div>;
    }else if(projectsFromApi && projectFromApi){
    return( 
        <div className="section projecten">
            <header className="projecten__header">
                {projectsFromApi.projects.length > 1 && !user.is_admin && <Link to="/overview" className="projecten__header__backlink">← Terug naar projecten overzicht</Link>}
                <h2 className="projecten__header__h2 main-title">{selectedProject.project_name}</h2>
                <div className="titleLinksWrapper">
                    {selectedProject.test_url && <a target="_blank" rel="noreferrer" href={selectedProject.design_url} className="titleLinksWrapper__button btn btn--greywhite">Bekijk website ontwerp</a>}
                    {selectedProject.design_url && <a target="_blank" rel="noreferrer" href={selectedProject.test_url} className="titleLinksWrapper__button btn btn--greywhite">Bekijk testomgeving</a>}
                    {!user.is_admin && <Link to="/offerte" className="titleLinksWrapper__button btn btn--greywhite">Bekijk kostenindicatie</Link>}
                </div>
            </header>
            <section className="status">
                <ProjectVerloop project={projectFromApi}/>
                <ProjectCoordinatie project_coordination={projectFromApi.project_coordination}/>
                <TotaalMeerwerk additional_work={projectFromApi.additional_work}/>
            </section>
            <section className="projectDetails">
                <h2 className="projectDetails--h2">Project details</h2>
                {projectFromApi.phases.map((phase, index) => {
                    if(phase.name.toLowerCase() === "projectcoördinatie"){return null;}
                    return <ProjectDetail key={index} id={selectedProject.pm_project_id} phase={phase}/>;
                })}
                {projectFromApi.additional_work && <Meerwerk additional_work={projectFromApi.additional_work}/>}

            </section>
        </div>
    )
    }
}

export default Projecten; 
