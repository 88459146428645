import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import CMSButton from "../CMSButton/CMSButton";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import "./Dashboard.scss";
import { useEffect, useState } from "react";

const Dashboard = () => {
    const [projectsFromApi, setProjectsFromApi] = useState();
    const [projectFromApi, setProjectFromApi] = useState();
    const [selectedProject, setSelectedProject] = useState();
    const {token, selectedProjectID} = useStateContext();

    useEffect(() => {
        axiosClient({
            method: 'get',
            url: '/api/v1/Projects',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjectsFromApi(response.data);
          });
        axiosClient({
            method: 'get',
            url: `/api/v1/Projects/${selectedProjectID}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjectFromApi(response.data);
        });
    },[token, selectedProjectID]);


    useEffect(() => {
        if(!projectsFromApi || !selectedProjectID)return;
        projectsFromApi.projects.forEach(project => {
            if(project.pm_project_id === selectedProjectID){
                setSelectedProject(project);
            }
        })
    },[projectsFromApi, selectedProjectID]);
        

    if(!selectedProject || !projectFromApi){
        return <div>Loading...</div>
    }

    return(
        <section className="section dashboard">
            <ProgressCircle project={selectedProject} projectFromApi={projectFromApi}/>
            <CMSButton project={selectedProject} />
        </section>
    );
}

export default Dashboard;