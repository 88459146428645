import "./SelectedItem.scss";
import { useEffect, useRef, useState } from "react";
import PlaceholderPFP from "../../../assets/placeholderPFP.webp";
import { useStateContext } from "../../../contexts/ContextProvider";
import axiosClient from "../../../axios-client";
import databaseIntToBool from "../../../helpers/databaseIntToBool";

const SelectedItem = ({changeArchived, item, setSelectedItemOpen}) => {
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [itemLinesFromApi, setItemLinesFromApi] = useState();
    const msgRef = useRef();
    const chatDivRef = useRef();
    const {token, user} = useStateContext();

    const status = ["Ongelezen","in behandeling","Afgerond"]

    const fetchItemLines = () => {
        axiosClient({
            method: 'get',
            url: `/api/v1/ItemLines/${item.id}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setItemLinesFromApi(response.data);
          });
    };
    useEffect(() => {
        axiosClient({
            method: 'get',
            url: `/api/v1/ItemLines/${item.id}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setItemLinesFromApi(response.data);
        });
    }, [token, item.id]);

    const onSendMessage = (ev) =>{

        ev.preventDefault();
        axiosClient({
            method: 'post',
            url: `/api/v1/ItemLines/`,
            data: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                item_id: item.id,
                message: msgRef.current.value,
                document: null,
                token: token,
            }
        }).then(function (response) {
            fetchItemLines();
            ev.target.reset();
            //TODO: email nieuw chatbericht in item
          })
          .catch(function (error) {
            console.log(error);
            });
    }
    if(chatDivRef.current){
        chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }

    return(
        <div className="selectedissue">
            <div onClick={()=>setSelectedItemOpen(false)} className="selectedissue__left"></div>
            <div className="selectedissue__right">
                <header className="selectedissue__right__header">
                    <nav className="selectedissue__right__header__nav">
                        <div>
                        <button onClick={changeArchived} className="selectedissue__right__header__nav__btn">
                            {!databaseIntToBool(parseInt(item.archived)) && <i className="fa-regular fa-folder"></i>}
                            {databaseIntToBool(parseInt(item.archived)) && <i className="fa-solid fa-folder"></i>}
                        </button>
                        <div className={`selectedissue__right__header__nav__status selectedissue__right__header__nav__status--${status[item.status_id - 1].toLowerCase()}`}>
                            {status[item.status_id -1]}
                        </div>
                        </div>
                        <i onClick={()=>setSelectedItemOpen(false)} className="fa-solid fa-xmark selectedissue__right__header__nav__close"></i>
                    </nav>
                    <div className="selectedissue__right__header__wrapper">
                        <h1 className="selectedissue__right__header__wrapper__title">{item.name}</h1>
                        {!descriptionOpen && <i onClick={()=>setDescriptionOpen(!descriptionOpen)} className="fa-solid fa-chevron-up selectedissue__right__header__wrapper__chevron"></i>}
                        {descriptionOpen && <i onClick={()=>setDescriptionOpen(!descriptionOpen)} className="fa-solid fa-chevron-down selectedissue__right__header__wrapper__chevron"></i>}
                    </div>
                    <div className={`selectedissue__right__header__descriptionwrapper ${descriptionOpen && "selectedissue__right__header__descriptionwrapper--open"}`}>
                        <p className="selectedissue__right__header__descriptionwrapper__description">{item.description}</p>
                    </div>
                </header>
                <div ref={chatDivRef} className="selectedissue__right__chat">
                    <div className="selectedissue__right__chat__message selectedissue__right__chat__message--right selectedissue__right__chat__message--big">
                        <header className="selectedissue__right__chat__message__header">
                            <div className="selectedissue__right__chat__message__header__userwrapper">
                                <figure className="selectedissue__right__chat__message__header__userwrapper__figure">
                                    <img src={PlaceholderPFP} alt="" />
                                </figure>
                                <p className="selectedissue__right__chat__message__header__userwrapper__by">{item.created_name}</p>
                            </div>
                            <p className="selectedissue__right__chat__message__header__date">{item.date}</p>
                        </header>
                        <div className="selectedissue__right__chat__message__content">
                            <h1 className="selectedissue__right__chat__message__content__opened">Klantnaam heeft een item geopend:</h1>
                            <h2 className="selectedissue__right__chat__message__content__title">{item.title}</h2>
                            <p className="selectedissue__right__chat__message__content__text">{item.description}</p>
                        </div>
                    </div>
                    {!itemLinesFromApi && <p>Loading...</p>}
                    {itemLinesFromApi && itemLinesFromApi.item_lines && itemLinesFromApi.item_lines.map((chatMessage, index) =>{
                        return(
                            <div key={index} className={`selectedissue__right__chat__message selectedissue__right__chat__message${(parseInt(chatMessage.customer_id) === parseInt(user.user_id)) ? "--right" : "--left"}`}>
                                <header className="selectedissue__right__chat__message__header">
                                    <div className="selectedissue__right__chat__message__header__userwrapper"> 
                                        <figure className="selectedissue__right__chat__message__header__userwrapper__figure">
                                            <img src={PlaceholderPFP} alt="" />
                                        </figure>
                                        <p className="selectedissue__right__chat__message__header__userwrapper__by">{chatMessage.created_name}</p>
                                    </div>
                                    <p className="selectedissue__right__chat__message__header__date">{chatMessage.created_date}</p>
                                </header>
                                <div className="selectedissue__right__chat__message__content">
                                    <p className="selectedissue__right__chat__message__content__text">{chatMessage.message}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <form onSubmit={(ev)=>onSendMessage(ev)} className="selectedissue__right__chatbar">
                    <input  ref={msgRef} placeholder="bericht..." className="selectedissue__right__chatbar__input" type="text" />
                    <button className="selectedissue__right__chatbar__button selectedissue__right__chatbar__button--send">
                        <i className="fa-regular fa-paper-plane"></i>
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SelectedItem;