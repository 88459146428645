import { useEffect, useState } from "react";
import "./ProjectDetailsTitle.scss";

const ProjectDetailsTitle = (props) =>{
    const [projectDetailsTitleClasses, setProjectDetailsTitleClasses] = useState("");
    const [projectDetailsOpen, setProjectDetailsOpen] = useState();

    useEffect(()=>{
        setProjectDetailsTitleClasses("projectDetailsTitle")
        setProjectDetailsOpen(true);
    },[]);

    const onProjectDetailsIconClick = () =>{
        setProjectDetailsOpen(!projectDetailsOpen);
        if(!projectDetailsOpen){
            setProjectDetailsTitleClasses("projectDetailsTitle")
            props.setProjectDetailsClasses(`projectDetails__detail__akkoordwrapper projectDetails__detail__info projectDetails__detail__info--${props.detailName}`)
        }else{
            setProjectDetailsTitleClasses("projectDetailsTitle projectDetailsTitle--isclosed")
            props.setProjectDetailsClasses(`projectDetails__detail__akkoordwrapper projectDetails__detail__info projectDetails__detail__info--${props.detailName} projectDetails__detail__info--isclosed`)
        }
    }

    return(
        <div className={projectDetailsTitleClasses}>
            <h3 className="projectDetailsTitle__title">{props.title}</h3>
            <i onClick={onProjectDetailsIconClick} className="fa-solid fa-minus"></i>
            <i onClick={onProjectDetailsIconClick} className="fa-solid fa-plus"></i>
        </div>
    );
}

export default ProjectDetailsTitle;
