import {Link, Navigate, Outlet, useLocation} from "react-router-dom";
import {ReactSVG} from "react-svg";
import "./DefaultLayout.scss";
import {useEffect, useRef, useState} from "react";
import NotificationsPopup from "./NotificationsPopup/NotificationsPopup.jsx";
import notificationIcon from "../assets/notificationIcon.webp";
import icon from "../assets/webvalue-icon.svg";
import navItemsData from "../data/navItemsData.js";
import PopoutNavItem from "./PopoutNavItem.jsx";
import webvaluelogo from "../assets/webvalue-logo.svg";
import { useStateContext } from "../contexts/ContextProvider";
import axiosClient from "../axios-client";

export default function DefaultLayout() {
    const { setToken, token, setUser, user , setSelectedProjectID} = useStateContext();
    const [notifOpen, setNotifOpen] = useState();
    const [accountOpen, setAccountOpen] = useState();
    const location = useLocation();
    const accountPopupRef = useRef();
        
    useEffect(()=>{
        setNotifOpen(false);
        setAccountOpen(false);
    },[]);

    window.addEventListener('click', ({target})=> {
        if(!accountPopupRef.current)return;
        if(!accountPopupRef.current.innerHTML.includes(target.innerHTML)){
            setAccountOpen(false);
        }
    });

    if(user.is_admin){
        return  <Navigate to="/adminoverview" />
    }

    const onLogout = () =>{
        axiosClient({
            method: 'post',
            url: `/api/v1/Logout`,
            data: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token
            }
        }).then(({data})=>{
                setToken(null)
                setUser(null)
                setSelectedProjectID(null)
            })
    }

    return(
    <div className="homePage">
        {notifOpen && <NotificationsPopup notifOpen={notifOpen} setNotifOpen={setNotifOpen}/>}
        <div className="logo">
            <ReactSVG className="logo__img" src={webvaluelogo}/>
        </div>
        <header className="header">
            <div className="logo logo--display">
                <ReactSVG className="logo--display__img" src={icon} alt="webvalue icon" />
            </div>
            <div>
                <div onClick={() => setNotifOpen(!notifOpen)} className="notifications">
                    <img src={notificationIcon} alt="notification icon" />
                </div>
                <div ref={accountPopupRef} onClick={()=>setAccountOpen(!accountOpen)} className="account">
                    <figure>
                        <img src={user.user_image_url} alt="" />
                    </figure>
                    <p>Mijn account</p>
                    {!accountOpen && <i className="fa-solid fa-chevron-down"></i>}
                    {accountOpen && <i className="fa-solid fa-chevron-up"></i>}
                    <div className={`account__popup ${accountOpen && "account__popup--open"}`}>
                        <h3 className="account__popup__text">Ingelogd als </h3>
                        <h2 className="account__popup__user">{user.name}</h2>
                        <button onClick={onLogout} className="account__popup__btn">Logout</button>
                    </div>
                    
                </div>
            </div>
        </header>
        <nav className="nav">
            {navItemsData.map((navItem, index)=>{
                    if(navItem.popout){
                        return <PopoutNavItem key={index} navItem={navItem}/>;
                    }
                    return(
                        <Link to={`/${navItem.element}`} key={navItem.text} className={`nav__item ${(location.pathname === `/${navItem.element}`)&& "selected"}`}>
                            <div className="nav__item__wrapper">
                                <ReactSVG className="nav__item__icon" src={navItem.icon} alt="" />
                                <p className="nav__item__title">{navItem.text}</p>
                            </div>
                        </Link>
                    );
                })
            }
        </nav>
        <div>
            {(window.location.pathname === "/berichten" || window.location.pathname === "/items") &&
            <div className="berichtissueswitch">
                <div className="berichtissueswitch__wrapper">
                    <div className="berichtissueswitch__wrapper__btnwrapper">
                        <Link to="/berichten" className="berichtissueswitch__wrapper__btnwrapper__btn">Updates</Link>
                        <Link to="/items" className="berichtissueswitch__wrapper__btnwrapper__btn">Issues</Link>
                        <div className={`berichtissueswitch__wrapper__btnwrapper__selected ${window.location.pathname === "/items" && "berichtissueswitch__wrapper__btnwrapper__selected--right"}`}></div>
                    </div>
                </div>
            </div>}
            <Outlet />
        </div>
        
        <div className="navfiller do_not_remove_thanks"></div>
        <nav className="mobilenav">
            <ul className="mobilenav__ul">
                {navItemsData.map((navItem, index) =>(
                    <Link to={`/${navItem.element}`} key={index} className={`mobilenav__ul__li ${((location.pathname === `/${navItem.element}`) || (location.pathname === "/items" && navItem.element === "berichten") )&& "mobilenav__ul__li--selected" }`}>
                        <ReactSVG className="mobilenav__ul__li__icon" src={navItem.icon} alt="" />
                        <label className="mobilenav__ul__li__title">{navItem.text}</label>
                    </Link>
                ))}
            </ul>
        </nav>
    </div>
    );
}
