import { useState } from "react";
import { dateDifferenceChecker } from "../../../helpers/dateDifferenceChecker";
import "./Item.scss";
import SelectedItem from "../SelectedItem/SelectedItem";
import databaseIntToBool from "../../../helpers/databaseIntToBool";
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../contexts/ContextProvider";

const Item = ({item, baseItems, setBaseItems}) => {
    const [selectedItemOpen, setSelectedItemOpen] = useState(false);
    const {token, selectedProjectID, user} = useStateContext();
    const params = {apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',token: token,submit: 'submit'}
    const status = ["Ongelezen","in behandeling","Afgerond"]

    const changeArchived = () =>{
        axiosClient({
            method: 'patch', 
            url: `/api/v1/Items`,
            data: {
                archived: databaseIntToBool(parseInt(item.archived)) ? "0" : "1",
                item_id: item.id,
                project_id: selectedProjectID
            },
            params: params
        }).then(function (response) {
            axiosClient({
                method: 'post',
                url: `/api/v1/Updates/`,
                data: {
                    token: token,
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    title: `${user.name} heeft een item gearchiveerd`,
                    message: `Er is een item gearchiveerd door ${user.name}.`,
                    project_id: selectedProjectID,
                }
            })
        });
        setBaseItems(baseItems.map(_item => _item.id === item.id ? {..._item, archived: databaseIntToBool(parseInt(item.archived)) ? "0" : "1"} : _item));
        setSelectedItemOpen(false);
    }

    return(
        <>
        {selectedItemOpen &&  <SelectedItem setSelectedItemOpen={setSelectedItemOpen} changeArchived={changeArchived} item={item}/>}
        <div className={`issues__issue ${selectedItemOpen && "body-overflow-hidden"}`}>
            <div className="issues__issue__textwrapper">
                <div onClick={()=>setSelectedItemOpen(!selectedItemOpen)} className="issues__issue__textwrapper__div">
                    <p className="issues__issue__textwrapper__div--title">{item.title}</p>
                    <p className="issues__issue__textwrapper__div--date">{dateDifferenceChecker(baseItems.filter(_item => parseInt(_item.id) === parseInt(item.id))[0].created_date)}</p>
                </div>
            </div>
            <div className="issues__issue__btnwrapper">
                <div className={`issues__issue__btnwrapper__status issues__issue__btnwrapper__status--${status[item.status_id - 1].toLowerCase()}`}>
                    {status[item.status_id - 1]}
                </div>
                <button onClick={changeArchived} className="issues__issue__btnwrapper__btn">
                    {!databaseIntToBool(parseInt(item.archived)) && <i className="fa-regular fa-folder"></i>}
                    {databaseIntToBool(parseInt(item.archived)) && <i className="fa-solid fa-folder"></i>}
                </button>
                <button onClick={()=>setSelectedItemOpen(!selectedItemOpen)} className="issues__issue__btnwrapper__btn">
                    <i className="fa-solid fa-info"></i>
                </button>
            </div>
        </div>
        </>
    );
}

export default Item;