import { useEffect, useState } from "react";
import calculatedPercentage from "../../helpers/calculatedPercentage";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";
import artwork from "../../assets/Artwork.webp";
import { Link} from "react-router-dom";

const ProjectOverzichtCard = ({ project }) => {
    const {token, setSelectedProjectID} = useStateContext();
    const [projectFromApi, setProjectFromApi] = useState();


    useEffect(()=>{
        axiosClient({
            method: 'get',
            url: `/api/v1/Projects/${project.pm_project_id}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjectFromApi(response.data); 
          });
    },[token, project.pm_project_id]);

    const goToProject = (id) => {
        setSelectedProjectID(id);
    }

    if(!projectFromApi)return <div>Loading...</div>;
    return(
        <Link to="/projectadmin" onClick={()=>goToProject(project.pm_project_id)} className="projectoverzicht__projecten__project">
            <div className="projectoverzicht__projecten__project__figurewrapper">
                <figure className="projectoverzicht__projecten__project__figure">
                    <img className="projectoverzicht__projecten__project__figure__img" src={artwork} alt="" />
                </figure>
            </div>
            <div className="projectoverzicht__projecten__project__content">
                <header className="projectoverzicht__projecten__project__content__header">
                    <h2 className="projectoverzicht__projecten__project__content__header__title">{project.project_name}</h2>
                    <p className="projectoverzicht__projecten__project__content__header__date">{project.start_date}</p>
                </header>
                <ul className="projectoverzicht__projecten__project__content__ul">
                    <li className="projectoverzicht__projecten__project__content__ul__li">Meerwerk: <span>{(projectFromApi.additional_work && projectFromApi.additional_work.hours_spend) || 0}</span></li>
                    <li className="projectoverzicht__projecten__project__content__ul__li">Projectcoördinatie: <span>{(projectFromApi.project_coordination && projectFromApi.project_coordination.hours_spend) || 0}/{(projectFromApi.project_coordination && projectFromApi.project_coordination.hours_total) || 0}</span></li>
                </ul>
                <div className="projectoverzicht__projecten__project__content__progressbar"><div className="projectoverzicht__projecten__project__content__progressbar--inner" style={{"width": `${calculatedPercentage(project.hours_spend, project.hours_total)}%`}}>{calculatedPercentage(project.hours_spend, project.hours_total).toFixed(1)}%</div></div>
            </div>
        </Link>
    )
}

export default ProjectOverzichtCard;