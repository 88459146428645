import { useEffect, useState } from "react";
import ProjectDetailsTitle from "../../ProjectDetailsTitle/ProjectDetailsTitle";
import AkkoordNodig from "../Components/AkkoorNodig/AkkoordNodig";
import GoedgekeurdDoor from "../Components/GoedgekeurdDoor/GoedgekeurdDoor";
import UrenBalk from "../Components/UrenBalk/UrenBalk";
import "./Meerwerk.scss";

const Meerwerk = ({additional_work}) =>{
    const [projectDetailsClasses, setProjectDetailsClasses] = useState("");

    useEffect(() =>{
        setProjectDetailsClasses("projectDetails__detail__info projectDetails__detail__info--meerwerk");
    },[])


    return(
        <div id="meerwerk" className="projectDetails__detail">
            <ProjectDetailsTitle detailName="meerwerk" setProjectDetailsClasses={setProjectDetailsClasses} title="Meerwerk"/>
            <div className={projectDetailsClasses}>
                {additional_work.phase_groups.map((item, index) => {
                    return(<div key={index}>
                        <h2>{item.name}</h2>
                        <div className={`projectDetails__detail__akkoordwrapper meerwerk__${item.name.toLowerCase()}wrapper`}>
                            {item.lines.map((line, index) => {
                                return(<div key={index} className="projectDetails__detail__info__wrapper">
                                    <UrenBalk text={line.name} date={line.date_added} hours={line.time_available}/>
                                    {line.approved_date && <GoedgekeurdDoor name={line.approved_name} date={line.approved_date}/>}
                                    {!line.approved_date && <AkkoordNodig phaseId={line.phase_id} projectLineId={line.id} />}
                                </div>)
                            })
                        }
                        </div>
                    </div>)
                    })
                }
            </div>
        </div>
    );
}

export default Meerwerk;