import databaseIntToBool from "../../../helpers/databaseIntToBool";
import "./SelectedBericht.scss";

const SelectedBericht = ({changeArchived, bericht, setSelectedMessageOpen, changeRead}) => {

    return(
        <div className="selectedbericht">
            <div onClick={()=>setSelectedMessageOpen(false)} className="selectedbericht__left"></div>
            <div className="selectedbericht__right">
                <header className="selectedbericht__right__header">
                    <nav className="selectedbericht__right__header__nav">
                    <div>
                        <button className="selectedbericht__right__header__nav__btn">
                            <i className="fa-regular fa-comment selectedbericht__right__header__nav__btn--icon"></i>
                        </button>
                        <button onClick={changeRead} className="selectedbericht__right__header__nav__btn">
                            {!databaseIntToBool(parseInt(bericht.read)) && <i className="fa-regular fa-eye-slash selectedbericht__right__header__nav__btn--icon"></i>}
                            {databaseIntToBool(parseInt(bericht.read)) && <i className="fa-regular fa-eye selectedbericht__right__header__nav__btn--icon"></i>}
                        </button>
                        <button onClick={changeArchived} className="selectedbericht__right__header__nav__btn">
                            {!databaseIntToBool(parseInt(bericht.archived)) && <i className="fa-regular fa-folder selectedbericht__right__header__nav__btn--icon"></i>}
                            {databaseIntToBool(parseInt(bericht.archived)) && <i className="fa-solid fa-folder selectedbericht__right__header__nav__btn--icon"></i>}
                        </button>
                    </div>
                    <i onClick={()=>setSelectedMessageOpen(false)} className="fa-solid fa-xmark selectedbericht__right__header__nav__close"></i>
                    </nav>
                    <h1 className="selectedbericht__right__header__title">{bericht.title}</h1>
                </header>
                <div className="selectedbericht__right__content">
                    {!bericht.message && <p className="selectedbericht__right__content__nodetails">Geen details gevonden voor dit bericht</p>}
                    {bericht.message && <p className="selectedbericht__right__content__details">{bericht.message}</p>}
                </div>
            </div>
        </div>
    );
        
    
}

export default SelectedBericht;