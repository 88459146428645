import "./TotaalMeerwerk.scss";

const TotaalMeerwerk = ({additional_work}) => {
    return(
        <div className="totaalmeerwerk">
            <h3 className="totaalmeerwerk__h3">Totaal meerwerk</h3>
            <div className="totaalmeerwerk__wrapper">
                <div className="totaalmeerwerk__wrapper__bar"><b  className="totaalmeerwerk__wrapper__bar--bold">{additional_work.hours_spend}/{additional_work.hours_total} uur </b>goedgekeurd</div>
                {(parseInt(additional_work.hours_spend) !== 0 || parseInt(additional_work.hours_total) !== 0) && <a className="totaalmeerwerk__wrapper__button btn" href="#meerwerk">Bekijk meerwerk</a>}
            </div>
        </div>
    )
}

export default TotaalMeerwerk;