import "../AdminItems/CreateItemPopup/CreateItemPopup.scss";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";

const CreateUpdatePopup = ({ setUpdatesFromApi, setCreateUpdatePopupOpen }) => {
    const {token, selectedProjectID} = useStateContext();

    const createUpdate = (e) =>{
        e.preventDefault();
        const title = e.target.title.value;
        const description = e.target.description.value;
    
        axiosClient({
            method: 'post',
            url: `/api/v1/Updates/`,
            data: {
                token: token,
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                title: description,
                description: description,
                message: title,
                project_id: selectedProjectID,
            }
        }).then(function (response) {
            axiosClient({
                method: 'get',
                url: '/api/v1/Updates',
                params: {
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    token: token,
                    projectId: selectedProjectID,
                    submit: 'submit'
                }
            }).then(function (response) {
                response.data.updates.reverse()
                setUpdatesFromApi(response.data);
              });
            axiosClient({
                method: 'post',
                url: '/api/v1/SendMail',
                data: {
                    token: token,
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    emailName: "Bericht toegevoegd",
                    projectId: selectedProjectID
                },
            })
          })
          .catch(function (error) {
            console.log(error);
            });
            setCreateUpdatePopupOpen(false);
    }

    return (
        <div className="createissuepopup">
            <div onClick={()=>setCreateUpdatePopupOpen(false)} className="createissuepopup__left"></div>
            <div className="createissuepopup__right">
                <header className="createissuepopup__right__header">
                    <h1 className="createissuepopup__right__header__title">Nieuwe update</h1>
                    <i onClick={()=>setCreateUpdatePopupOpen(false)} className="fa-solid fa-xmark createissuepopup__right__header__close"></i>
                </header>
                <form className="createissuepopup__right__form" onSubmit={(e)=>createUpdate(e)}>
                    <div className="createissuepopup__right__form__inputwrapper">
                        <label className="createissuepopup__right__form__inputwrapper__label" htmlFor="title">Titel</label>
                        <input className="createissuepopup__right__form__inputwrapper__input" type="text" name="title" id="title" placeholder="Titel" maxLength="40"/>
                    </div>
                    <div className="createissuepopup__right__form__textareawrapper">
                        <label className="createissuepopup__right__form__textareawrapper__label" htmlFor="description">Beschrijf uw probleem</label>
                        <textarea  className="createissuepopup__right__form__textareawrapper__textarea" name="description" id="description" placeholder="Beschrijving"></textarea>
                    </div>
                    <button className="createissuepopup__right__form__button btn" type="submit">Verstuur</button>
                </form>
            </div>
        </div>
    );
}

export default CreateUpdatePopup;