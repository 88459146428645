import "./CreateItemPopup.scss";
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../contexts/ContextProvider";

const CreateItemPopup = ({ setItemsFromApi, setCreateItemPopupOpen }) => {
    const {token, selectedProjectID, user} = useStateContext();

    const createIssue = (e) =>{
        e.preventDefault();
        const title = e.target.title.value;
        const description = e.target.description.value;
    
        axiosClient({
            method: 'post',
            url: `/api/v1/Items/`,
            data: {
                token: token,
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                title: title,
                description: description, 
                project_id: selectedProjectID,
            }
        }).then(function (response) {
            axiosClient({
                method: 'get',
                url: '/api/v1/Items',
                params: {
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    token: token,
                    projectId: selectedProjectID,
                    submit: 'submit'
                }
            }).then(function (response) {
                response.data.items.reverse()
                setItemsFromApi(response.data);
              });
            axiosClient({
                method: 'post',
                url: '/api/v1/SendMail',
                data: {
                    token: token,
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    emailName: "Item toegevoegd",
                    projectId: selectedProjectID
                },
            })
            axiosClient({
                method: 'post',
                url: `/api/v1/Updates/`,
                data: {
                    token: token,
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    title: `${user.name} heeft een nieuw item toegevoegd`,
                    message: `Er is een nieuw item toegevoegd aan het project door ${user.name}.`,
                    project_id: selectedProjectID,
                }
            })
          })
        setCreateItemPopupOpen(false);
    }

    return (
        <div className="createissuepopup">
            <div onClick={()=>setCreateItemPopupOpen(false)} className="createissuepopup__left"></div>
            <div className="createissuepopup__right">
                <header className="createissuepopup__right__header">
                    <h1 className="createissuepopup__right__header__title">Nieuw item</h1>
                    <i onClick={()=>setCreateItemPopupOpen(false)} className="fa-solid fa-xmark createissuepopup__right__header__close"></i>
                </header>
                <form className="createissuepopup__right__form" onSubmit={(e)=>createIssue(e)}>
                    <div className="createissuepopup__right__form__inputwrapper">
                        <label className="createissuepopup__right__form__inputwrapper__label" htmlFor="title">Titel</label>
                        <input className="createissuepopup__right__form__inputwrapper__input" type="text" name="title" id="title" placeholder="Titel" maxLength="40"/>
                    </div>
                    <div className="createissuepopup__right__form__textareawrapper">
                        <label className="createissuepopup__right__form__textareawrapper__label" htmlFor="description">Beschrijf uw probleem</label>
                        <textarea  className="createissuepopup__right__form__textareawrapper__textarea" name="description" id="description" placeholder="Beschrijving"></textarea>
                    </div>
                    <button className="createissuepopup__right__form__button btn" type="submit">Toevoegen</button>
                </form>
            </div>
        </div>
    );
}

export default CreateItemPopup;