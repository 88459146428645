import "./Berichten.scss";
import { useEffect, useState } from "react";
import calculatedPercentage from "../../helpers/calculatedPercentage";
import { filterArchived, filterImportant, filterUnread } from "../../helpers/FilterBerichten";
import Bericht from "./Bericht/Bericht";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";

const Berichten = () => {
    const {token, selectedProjectID} = useStateContext();

    const [updatesFromApi, setUpdatesFromApi] = useState();
    const [amountToBeRendered, setAmountToBeRendered] = useState(5);
    const [berichten, setBerichten] = useState();
    const [baseBerichten, setBaseBerichten] = useState();

    const [importantFilter, setImportantFilter] = useState(false);
    const [unreadFilter, setUnreadFilter] = useState(false);
    const [archivedFilter, setArchivedFilter] = useState(false);

    const _setArchivedFilter = () =>{
        setArchivedFilter(true);
        setImportantFilter(false);
        setUnreadFilter(false);
    }

    useEffect(() => {
        axiosClient({
            method: 'get',
            url: '/api/v1/Updates',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                projectId: selectedProjectID,
                submit: 'submit'
            }
        }).then(function (response) {
            if(response.data.updates){response.data.updates.reverse()
            setUpdatesFromApi(response.data);}
          });
    },[token]);

    useEffect(()=>{
        if(updatesFromApi){
            setBaseBerichten(updatesFromApi.updates);
            setBerichten(updatesFromApi.updates);
        }
    },[updatesFromApi])

    useEffect(()=>{ 
        if(!berichten) return;
        if(amountToBeRendered > berichten.length){ 
            setAmountToBeRendered(berichten.length);
        }
    },[amountToBeRendered, berichten])

    useEffect(()=>{
        if(!berichten) return;
        if (berichten.length > 5){ setAmountToBeRendered(5)
        }else{ setAmountToBeRendered(berichten.length) }
    },[berichten])

    useEffect(()=>{
        if(!baseBerichten)return;
        let _berichten = baseBerichten;
        if(importantFilter){
            _berichten = filterImportant(_berichten);
        }
        if(unreadFilter){
            _berichten = filterUnread(_berichten);
        }
        _berichten = filterArchived(_berichten, archivedFilter);
        setBerichten(_berichten);
    },[importantFilter, unreadFilter, archivedFilter, baseBerichten])

    const loadMoreMessages = () =>{
        setAmountToBeRendered(amountToBeRendered + 5);
    }

    return(
        <section className="section berichten">
            <h1 className="berichten__title main-title">Updates</h1>
            <header className="berichten__header">
                <div className="berichten__header__filterwrapper">
                    {!archivedFilter && <><button onClick={()=>setImportantFilter(!importantFilter)} className={`btn btn--bluewhite ${importantFilter && "btn--bluewhite--selected"}`}><i className="fa-solid fa-triangle-exclamation"></i>Important</button>
                    <button onClick={()=>setUnreadFilter(!unreadFilter)} className={`btn btn--bluewhite ${unreadFilter && "btn--bluewhite--selected"}`}><i className="fa-solid fa-eye-slash"></i>Unread</button></>}
                </div>
                {!archivedFilter && <button onClick={_setArchivedFilter} className="berichten__header__archivebutton">Bekijk archief</button>}
                {archivedFilter && <button onClick={()=>setArchivedFilter(false)} className="berichten__header__archivebutton">Terug naar huidige updates</button>}
            </header>
            {!berichten && <p>Loading...</p>}
            {berichten &&<div className="berichten__berichtwrapper">
            {berichten.map((bericht, index) =>{
                if(index > amountToBeRendered)return null;
                return <Bericht key={index} bericht={bericht} baseBerichten={baseBerichten} setBaseBerichten={setBaseBerichten}/>
            })}
            </div>}
            {berichten &&
            <footer className="berichten__footer">
                <p className="berichten__footer__amountshown">Toont {amountToBeRendered} van de {berichten.length} berichten</p>
                <div className="berichten__footer__amountprogressbar">
                    <div className="berichten__footer__amountprogressbar--progress" style={{width: `${calculatedPercentage(amountToBeRendered, berichten.length)}%`}}></div>
                </div>
                {!(amountToBeRendered >= berichten.length) &&
                    <button onClick={loadMoreMessages} className="berichten__footer__loadmorebutton btn">Meer updates inladen</button>
                }
            </footer>
            }
        </section>
    );
}

export default Berichten;