import "./AkkoordNodig.scss";
import "../../../../../scss/basics.scss";
import { useState } from "react";
import SignaturePopup from "../../../../../components/SignaturePopup/SignaturePopup.jsx";
import { useStateContext } from "../../../../../contexts/ContextProvider.jsx";

const AkkoordNodig = ({phaseData, setPhaseData ,phaseId, projectLineId}) =>{
    const {user} = useStateContext();
    const [signaturePopupOpen, setSignaturePopupOpen] = useState(false);

    return(
        <div className="akkoordNodig">
            {signaturePopupOpen && <SignaturePopup phaseData={phaseData} setPhaseData={setPhaseData} setSignaturePopupOpen={setSignaturePopupOpen} setPhaseId={phaseId} projectLineId={projectLineId}/>}
            <p className="akkoordNodig__p">Akkoord nodig</p>
            {!user.is_admin && <button className="btn btn--projectdetails" onClick={()=>setSignaturePopupOpen(!signaturePopupOpen)}>Ik ga akkoord</button>}
        </div>
    );
}

export default AkkoordNodig;