import "./ProjectCoordinatie.scss";

const ProjectCoordinatie =({project_coordination}) =>{

    return(
        <div className="projectCoordinatie">
            <header className="projectCoordinatie__header">
                <h3>Projectcoördinatie</h3>
            </header>
            <div className="projectCoordinatie__progressBar">
                <div style={{width: (project_coordination.percentage || 0)+"%"}} className="projectCoordinatie__progressBar--progress"><p style={{marginRight: '.5rem'}}><b>{(project_coordination.hours_spend || 0)}/{(project_coordination.hours_total || 0)} uur</b> gebruikt{' '}</p>{parseFloat((project_coordination.percentage || 0).toFixed(2))}%</div>
                <div style={{width: (100 - project_coordination.percentage || 100)+"%"}} className="projectCoordinatie__progressBar--progress-left"></div>
            </div>
        </div>
    );
}

export default ProjectCoordinatie;