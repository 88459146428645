import {Link, Navigate, Outlet, useLocation} from "react-router-dom";
import {ReactSVG} from "react-svg";
import "./DefaultLayout.scss";
import {useEffect, useRef, useState} from "react";
import icon from "../assets/webvalue-icon.svg";
import webvaluelogo from "../assets/webvalue-logo.svg";
import adminNavItemsData from "../data/adminNavItemsData.js";
import { useStateContext } from "../contexts/ContextProvider";
import axiosClient from "../axios-client";

export default function AdminLayout() {
    const accountPopupRef = useRef();
    const [accountOpen, setAccountOpen] = useState();
    const {user, setUser, token, setToken, selectedProjectID, setSelectedProjectID} = useStateContext();
    const [projects, setProjects] = useState();
    const location = useLocation();

    useEffect(()=>{
        setAccountOpen(false);
        axiosClient({
            method: 'get',
            url: '/api/v1/Projects',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjects(response.data);
          });
    },[token]);

    const selectedProject = useRef();

    window.addEventListener('click', ({target})=> {
        if(!accountPopupRef.current)return;
        if(!accountPopupRef.current.innerHTML.includes(target.innerHTML)){
            setAccountOpen(false);
        }
    });
    

    if(!user.is_admin){
        return  <Navigate to="/projecten" />
    }

    const onLogout = () =>{
        axiosClient({
            method: 'post',
            url: `/api/v1/Logout`,
            data: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token
            }
        }).then(()=>{
                setToken(null)
                setUser(null)
                setSelectedProjectID(null)
            })
    }

    const onProjectChange = () =>{
        setSelectedProjectID(selectedProject.current.selectedOptions[0].id);
    }

    return(
        <div className="homePage">
            <div className="logo">
                <ReactSVG className="logo__img" src={webvaluelogo}/>
            </div>
            <header className="header">
                <div className="logo logo--display">
                    <ReactSVG className="logo--display__img" src={icon} alt="webvalue icon" />
                </div>  
                <div>
                    <div ref={accountPopupRef} onClick={()=>setAccountOpen(!accountOpen)} className="account account--admin">
                        <figure>
                            <img src={user.user_image_url} alt="" />
                        </figure>
                        <p>Mijn account</p>
                        {!accountOpen && <i className="fa-solid fa-chevron-down"></i>}
                        {accountOpen && <i className="fa-solid fa-chevron-up"></i>}
                        <div className={`account__popup ${accountOpen && "account__popup--open"}`}>
                            <h3 className="account__popup__text">Ingelogd als </h3>
                            <h2 className="account__popup__user">{user.name}</h2>
                            <button onClick={onLogout} className="account__popup__btn">Logout</button>
                        </div>
                    </div>
                </div> 
            </header>
            <nav className="nav">
                <ul className="nav__list">
                    <div className="nav__list__projects">
                        <select value={selectedProjectID} onChange={onProjectChange} ref={selectedProject} className="nav__list__projects__select" name="project" id="select_project">
                        {projects && projects.projects.map((project, index)=>{
                            return <option key={index} id={project.pm_project_id} className="nav__list__projects__select__option" value={project.pm_project_id}>{project.project_name}</option>;
                        })}
                        </select>
                    </div>
                    {adminNavItemsData.map((navItem, index)=>(
                        <Link key={index} to={`/${navItem.element}`} className={`nav__item ${(location.pathname === `/${navItem.element}`) && "selected"}`}> 
                            <div className="nav__item__wrapper">
                                <ReactSVG className="nav__item__icon" src={navItem.icon} />
                                <label className="nav__item__title">{navItem.text}</label>
                            </div>
                        </Link>
                    ))}
                </ul>
            </nav>
            <Outlet />
            <div className="navfiller do_not_remove_thanks"></div>
            <nav className="mobilenav">
                <ul className="mobilenav__ul">
                    {adminNavItemsData.map((navItem, index) =>(
                        <Link to={`/${navItem.element}`} key={index} className={`mobilenav__ul__li ${(location.pathname === `/${navItem.element}`) && "mobilenav__ul__li--selected"}`}>
                            <ReactSVG className="mobilenav__ul__li__icon" src={navItem.icon} alt="" />
                            <label className="mobilenav__ul__li__title">{navItem.text}</label>
                        </Link>
                    ))}
                </ul>
            </nav>
        </div>
    );
}
