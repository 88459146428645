import "./ProgressCircle.scss";
import calculatedPercentage from "../../helpers/calculatedPercentage";

const ProgressCircle = ({project, projectFromApi}) => {

    const projectPercentage = calculatedPercentage(project.hours_spend, project.hours_total).toFixed(0);

    return(
        <div className="progresscircle">
            <div style={{"--percentage": projectPercentage+"%"}} className={`progresscircle__circle ${(projectPercentage > 100) && 'progresscircle__circle--overtime'}`}>
                <div style={{"--percentage": (projectFromApi.project_coordination.percentage || 0)+"%"}} className={`progresscircle__circle progresscircle__circle2 ${(projectPercentage > 100) && 'progresscircle__circle--overtime'}`}>
                    <h3 className="progresscircle__circle__title">{projectPercentage}%</h3>
                    <h3 className="progresscircle__circle__subtitle">{parseFloat(project.hours_spend.toFixed(2))}/{project.hours_total.toFixed(0)}</h3>
                    <h3 className="progresscircle__circle__subtitle">{parseFloat((projectFromApi.project_coordination.hours_spend || 0).toFixed(2))}/{(projectFromApi.project_coordination.hours_total || 0).toFixed(0)}</h3>
                </div>
            </div>
            {/* <h2 className="progresscircle__title">Voortgang</h2> */}
        </div>
    );
}

export default ProgressCircle;