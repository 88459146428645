import "./CMSButton.scss";

const CMSButton = ({project}) => {

    return(
        <>
            {project.url && <a className="cmsbutton" href={`${project.url}/cms`}>Naar CMS</a>}
            {!project.url && <p onClick={()=>alert("Link is niet ingevuld!")} className="cmsbutton">Naar CMS</p>}
        </>
    );
}

export default CMSButton;