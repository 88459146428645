// Icons
import letterIcon from "../assets/letterIcon.svg";
import documentsIcon from "../assets/documentsIcon.svg";
import projectsIcon from "../assets/projectsIcon.svg";


const navItems = [
    {
        text: "Project",
        selected: true,
        icon: projectsIcon,
        element: "projectadmin",
    },
    {
        text: "Updates",
        selected: false,
        icon: letterIcon,
        element: "adminupdates",
    },
    {
        text: "Items",
        selected: false,
        icon: letterIcon,
        element: "adminitems",
    },
    {
        text: "Documents",
        selected: false,
        icon: documentsIcon,
        element: "admindocuments",
    },
]

export default navItems;
