import databaseIntToBool from "./databaseIntToBool";

export const filterArchived = (toBeSearchedArray, archivedFilter) =>{
    if(!toBeSearchedArray)return;
    if(archivedFilter){
    return toBeSearchedArray.filter(item =>{
        if (databaseIntToBool(parseInt(item.archived))){
            return item;
        }
        return null;
    })}else{
        return toBeSearchedArray.filter(item =>{
            if (!databaseIntToBool(parseInt(item.archived))){
                return item;
            }
            return null;
        })
    }
}

export const filterImportant = (toBeSearchedArray) =>{
    if(!toBeSearchedArray)return;
    return toBeSearchedArray.filter(item =>{
        if (databaseIntToBool(parseInt(item.important))){
            return item;
        }
        return null;
    })
}

export const filterUnread = (toBeSearchedArray) =>{
    if(!toBeSearchedArray)return;
    return toBeSearchedArray.filter(item =>{
        if (!databaseIntToBool(parseInt(item.read))){
            return item;
        }
        return null;
    })
}