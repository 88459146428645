import "./ForgotPasswordPopup.scss";
import axiosClient from "../../axios-client";
import {useStateContext} from "../../contexts/ContextProvider";
import {useEffect, useState} from "react";
const ForgotPasswordPopup = ({changePopupOpen, popupOpen}) =>{

    const {token} = useStateContext();
    const [email, setEmail] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    const handleChange = event => {

        setEmail(event.target.value);
    };

    useEffect(() => {
        if(success){
            setTimeout(() => {
                changePopupOpen();
            }, 5000)
        }
    }, [success]);

    const onForgotPasswordSubmit = () =>{


        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(re.test(email)){
            setError(null);
            setSuccess(null);
            axiosClient({
                method: 'post',
                url: `/api/v1/ForgetPassword`,
                data: {
                    apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                    email: email,
                },
            }).then(function (response) {
                if(response.data.status === false)
                {
                    setError(response.data.error);
                } else {
                    setSuccess(response.data.success);
                }
            })
        }

    }

    if(popupOpen){
        return(
            <div className="forgotPWPopup">
                {success && <div className="forgotPWPopup__success">
                    <p className="forgotPWPopup__success__p">{success}</p>
                </div> }
                {!success && <form className="forgotPWPopup__form" onSubmit={(e) => {e.preventDefault()}} action={() => onForgotPasswordSubmit}>
                    <div className="forgotPWHeader">
                        <h1 className="forgotPWHeader__title">Wachtwoord vergeten?</h1>
                    </div>
                    
                    
                    <div className="forgotPWPopup__form__inputWrapper">
                        <label className="forgotPWPopup__form__inputWrapper__label" htmlFor="email">email</label>
                        <input className="forgotPWPopup__form__inputWrapper__input" type="text" onChange={handleChange} value={email} name="email" id="email"/>
                        {error && <div className="forgotPWPopup__errors">
                            <p className="forgotPWPopup__error__p">{error}</p>
                        </div>}
                    </div>
                    <div className="forgotPWPopup__form__buttonWrapper">
                        <button onClick={onForgotPasswordSubmit} className="forgotPWPopup__form__buttonWrapper__resetButton">Reset wachtwoord</button>
                        <button onClick={changePopupOpen} className="forgotPWPopup__form__buttonWrapper__backButton">Terug</button>
                    </div>
                </form>}
            </div>
        );
    }

}

export default ForgotPasswordPopup;