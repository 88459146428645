const notifsData = [
    {
        //pfp: ,
        name: "Olga",
        task: "heeft een nieuw bericht achtergelaten",
        time: "Gisteren",
        id: 1,
    },
    {
        //pfp: ,
        name: "Ruinemans",
        task: "heeft bedrijfsgegevens aangepast",
        time: "Twee weken geleden",
        id: 2,
    },
    {
        //pfp: ,
        name: "Dennis",
        task: "heeft de vervolgpagina's ontworpen",
        time: "Drie weken geleden",
        id: 3,
    },
    {
        //pfp: ,
        name: "Jelle",
        task: "heeft een support aanvraag afgerond",
        time: "Drie weken geleden",
        id: 4,
    },
]

export default notifsData;