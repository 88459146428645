import "./Items.scss";
import { useEffect, useState } from "react";
import calculatedPercentage from "../../helpers/calculatedPercentage";
import { ReactSVG } from "react-svg";
import uploadfilesicon from "../../assets/uploadfilesicon.svg";
import { filterArchived } from "../../helpers/FilterBerichten";
import Item from "./Item/Item";
import CreateItemPopup from "../AdminItems/CreateItemPopup/CreateItemPopup";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";


const Items = () => {
    const {token, selectedProjectID} = useStateContext();
    const [itemsFromApi, setItemsFromApi] = useState();

    const [amountToBeRendered, setAmountToBeRendered] = useState(5);
    const [baseItems, setBaseItems] = useState();
    const [items, setItems] = useState();
    const [createItemPopupOpen, setCreateItemPopupOpen] = useState(false);

    const [statusFilter, setStatusFilter] = useState(false);
    const [archivedFilter, setArchivedFilter] = useState(false);

    const status = ["Ongelezen","In behandeling","Afgerond"] 
    
    const _setArchivedFilter = () =>{
        setArchivedFilter(true);
        setStatusFilter(false);
    }

    useEffect(()=>{ 
        if(!itemsFromApi || !items) return;
        if(amountToBeRendered > items.length){
            setAmountToBeRendered(items.length);
        }
    },[amountToBeRendered, items, itemsFromApi])

    useEffect(()=>{
        if(!itemsFromApi || !items) return;
        if (items.length > 5){ setAmountToBeRendered(5)
        }else{ setAmountToBeRendered(items.length) }
    },[items, itemsFromApi])
    
    useEffect(() => {
        axiosClient({
            method: 'get',
            url: '/api/v1/Items',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                projectId: selectedProjectID,
                submit: 'submit'
            }
        }).then(function (response) {
            if(response.data.items){response.data.items.reverse()
            setItemsFromApi(response.data);}
          });
    },[token, selectedProjectID]);

    useEffect(() => {
        if(!itemsFromApi)return;
        setBaseItems(itemsFromApi.items);
        setItems(itemsFromApi.items);
    }, [itemsFromApi]);
    
    const loadMoreMessages = () =>{
        setAmountToBeRendered(amountToBeRendered + 5);
    }

    useEffect(()=>{
        let _items = baseItems;
        _items = filterArchived(_items, archivedFilter);
        if(statusFilter){
            _items = _items.filter(item => {
                if(status[item.status_id - 1].toLowerCase() === statusFilter){
                    return item;
                }
                return null;
            });
        }
        setItems(_items);
    },[archivedFilter, statusFilter, baseItems])

    const _setStatusFilter = (status) =>{
        if(statusFilter === status){
            setStatusFilter(false);
        }else{
            setStatusFilter(status);
        }
    }


    return(
        <>
        {createItemPopupOpen &&  <CreateItemPopup setItemsFromApi={setItemsFromApi} baseItems={baseItems} setBaseIssues={setBaseItems} setCreateItemPopupOpen={setCreateItemPopupOpen}/>}
        <section className="section issues">
            <h1 className="issues__title main-title">Items</h1>
            <header className="issues__header">
                <div className="issues__header__filterwrapper">
                    {!archivedFilter && <>
                    <button onClick={()=>_setStatusFilter("ongelezen")} className={`btn btn--bluewhite ${(statusFilter === "ongelezen") && "btn--bluewhite--selected"}`}><i className="fa-solid fa-eye-slash"></i>Ongelezen</button>
                    <button onClick={()=>_setStatusFilter("in behandeling")} className={`btn btn--bluewhite ${(statusFilter === "in behandeling") && "btn--bluewhite--selected"}`}><i className="fa-solid fa-gears"></i>in behandeling</button>
                    <button onClick={()=>_setStatusFilter("afgerond")} className={`btn btn--bluewhite ${(statusFilter === "afgerond") && "btn--bluewhite--selected"}`}><i className="fa-solid fa-circle-check"></i>Afgerond</button></>
                    }
                </div>
                <div className="issues__header__buttonwrapper">
                    {!archivedFilter && <><button onClick={_setArchivedFilter} className="issues__header__buttonwrapper__archive">Bekijk archief</button>
                    <button onClick={()=>setCreateItemPopupOpen(!createItemPopupOpen)} className="issues__header__buttonwrapper__create btn btn--greenwhite"><ReactSVG className="document__header__uploadbutton--icon" src={uploadfilesicon} />Nieuw</button></>}
                    {archivedFilter && <button onClick={()=>setArchivedFilter(false)} className="issues__header__buttonwrapper__archive">Terug naar actieve items</button>}
                </div>
            </header>
            
            {!itemsFromApi &&<p>Loading...</p>}
            <div className="issues__issuewrapper">
                {items && items.map((item, index) => {
                    if(index >= amountToBeRendered)return null;
                    return <Item key={index} item={item} baseItems={baseItems} setBaseItems={setBaseItems}/>
                })}
            </div>
            {items && 
                <footer className="issues__footer">
                <p className="issues__footer__amountshown">Toont {amountToBeRendered} van de {items.length} items</p>
                <div className="issues__footer__amountprogressbar">
                    <div className="issues__footer__amountprogressbar--progress" style={{width: `${calculatedPercentage(amountToBeRendered, items.length)}%`}}></div>
                </div>
                {!(amountToBeRendered >= items.length) &&
                    <button onClick={loadMoreMessages} className="issues__footer__loadmorebutton btn">Meer items inladen</button>
                }

                </footer>
            }   
        </section>
        </>
    );
}

export default Items;