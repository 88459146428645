import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import "./DefaultLayout.scss";
import capNumberAt from "../helpers/capNumberAt";
import { useStateContext } from "../contexts/ContextProvider";
import databaseIntToBool from "../helpers/databaseIntToBool";
import axiosClient from "../axios-client";

const PopoutNavItem = ({navItem}) => {
    const {token, selectedProjectID} = useStateContext();
    const [updatesFromApi, setUpdatesFromApi] = useState();
    const [itemsFromApi, setItemsFromApi] = useState();
    const [openItems, setOpenItems] = useState([]);
    const [unreadUpdates, setUnreadUpdates] = useState([]);
    const location = useLocation();


    useEffect(() => {
        axiosClient({
            method: 'get',
            url: '/api/v1/Updates',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                projectId: selectedProjectID,
                submit: 'submit'
            }
        }).then(function (response) {
            setUpdatesFromApi(response.data);
          });
        axiosClient({
            method: 'get',
            url: '/api/v1/Items',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                projectId: selectedProjectID,
                submit: 'submit'
            }
        }).then(function (response) {
            setItemsFromApi(response.data);
          });
    },[token]);
    useEffect(()=>{
        if(!itemsFromApi)return;
        if(!itemsFromApi.items)return;
        setOpenItems(itemsFromApi.items.filter(update => !databaseIntToBool(parseInt(update.archived)))); //filter out archived items
    },[itemsFromApi])
    useEffect(()=>{
        if(!updatesFromApi)return;
        if(!updatesFromApi.updates)return;
        let _unreadUpdates = updatesFromApi.updates.filter(update => !databaseIntToBool(parseInt(update.read))); //filter out read updates
        setUnreadUpdates(_unreadUpdates.filter(update => !databaseIntToBool(parseInt(update.archived))));  //filter out archived updates
    },[updatesFromApi])

    let berichtenAmount = 0;
    if(updatesFromApi || itemsFromApi){
        berichtenAmount = unreadUpdates.length + openItems.length
    }

    return (
        <>
        <Link to="/berichten"  className={`nav__item ${((location.pathname === "/berichten") || (location.pathname === "/items")) && "selected"}`} >
            <div className="nav__item__wrapper">
                <ReactSVG className="nav__item__icon" src={navItem.icon} alt="" />
                <p className="nav__item__title">{navItem.text}</p>
                {<p className="notifthingy">{capNumberAt(berichtenAmount, 9)}</p>}
            </div>
        </Link>
        <div className={`nav__item__popout ${(location.pathname === "/berichten") && "nav__item__popout--open"} ${(useLocation().pathname === "/items") && "nav__item__popout--open"}`}>
            <Link className="LinkElement nav__item__popout__item" to="/berichten">
                <p className={`nav__item__popout__item__title ${(location.pathname === "/berichten") && "nav__item__popout__item__title--selected"}`}>Updates</p>
                {(unreadUpdates.length > 0) && <p className="nav__item__popout__item__amount nav__item__popout__item__amountblue">Ongelezen {unreadUpdates && capNumberAt(unreadUpdates.length, 99)}</p>}
            </Link>
            <Link className="LinkElement nav__item__popout__item" to="/items">
                <p className={`nav__item__popout__item__title ${(location.pathname === "/items") && "nav__item__popout__item__title--selected"}`}>Items</p>
                {(openItems.length > 0) && <p className="nav__item__popout__item__amount nav__item__popout__item__amountred">Openstaand {openItems && capNumberAt(openItems.length, 99)}</p>}
            </Link>
        </div>
        </>
    );
}
    
export default PopoutNavItem;