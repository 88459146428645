import { extensionChecker } from "./extensionChecker";

export const filterDocumentsByTitle = (toBeSearchedArray, toBeSearchedTitle) =>{
    return toBeSearchedArray.filter(document => {
        if(document.title.toUpperCase().indexOf(toBeSearchedTitle.toUpperCase()) !== -1 ){
            return document;
        }
        return null;
    });
}

export const filterDocumentsByType = (toBeSearchedArray, documentTypeToBeFiltered) =>{
    return toBeSearchedArray.filter(document =>{
        if (extensionChecker(document.extension) === documentTypeToBeFiltered){
            return document;
        }
        return null;
    })
}

export const filterDocumentsByUser = (toBeSearchedArray, userToBeFiltered) =>{
    if(!userToBeFiltered){
        return toBeSearchedArray;
    }
    return toBeSearchedArray.filter(document =>{
        if (document.company_name === userToBeFiltered){
            return document;
        }
        return null;
    })
}
